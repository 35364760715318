import Modal from 'react-modal';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import * as yup from 'yup';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { format, isEqual, parseISO } from 'date-fns';

import {
  Container,
  ContainerModalInfo,
  ContainerInputs,
  Header,
  Line,
  ExitButton,
  ConfirmButton,
  ContainerButtons,
} from './styles';
import { Label, InputContainer } from './InputFieldModal/styles';
import { useAuth } from '../../../../hooks/auth';
import { IActivityCSDTO } from '../dtos/IActivityCSDTO';
import api from '../../../../services/api';
import { InputsTimeContainer, Select } from '../ModalCreateActivity/styles';
import { IIntervalsDTO } from '../dtos/IIntervalsDTO';
import { InputFieldModal } from './InputFieldModal';
import { IActivitiesInADayDTO } from '../dtos/IActivitiesInADayDTO';

Modal.setAppElement('#root');

interface ModalCreateActivityFromProvidenceProps {
  isOpen: boolean;
  onRequestClose: () => void;
  activity: IActivityCSDTO;
  observation: string;
  activitiesInADay: IActivitiesInADayDTO;
  setActivitiesInADay: (actvities: IActivitiesInADayDTO) => void;
  updateActivityClose: () => void | undefined;
  activityType: string;
}

interface FormData {
  title: string;
  type: string;
  time_init: Date;
  time_end: Date;
  hubspot_deal_id?: string;
  observation?: string;
  providence_id?: string;
  date: string;
}

export function ModalCreateActivityFromActivity({
  isOpen,
  onRequestClose,
  activity,
  observation,
  activitiesInADay,
  setActivitiesInADay,
  updateActivityClose,
  activityType,
}: ModalCreateActivityFromProvidenceProps): JSX.Element {
  const { user } = useAuth();
  const [intervals, setIntervals] = useState<IIntervalsDTO>(
    {} as IIntervalsDTO,
  );
  const [date, setDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [formData, setFormData] = useState<FormData>({} as FormData);

  useEffect(() => {
    api
      .get(`/schedule-cs/available-hours?date=${date}&user_id=${user.id}`)
      .then(response => {
        setIntervals(response.data);
      });

    if (!isOpen) {
      setFormData({} as FormData);
    }
  }, [date, isOpen, user.id]);

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const { name, value } = event.target;

      if (name === 'date') {
        setDate(format(new Date(`${value} 3:00`), 'yyyy-MM-dd'));
      }

      setFormData({ ...formData, [name]: value });
    },
    [formData],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const timeInit = `${formData.date} ${formData.time_init}`;
      const timeEnd = `${formData.date} ${formData.time_end}`;

      const body = {
        user_id: user.id,
        title: formData.title,
        type: activityType,
        observation: observation || undefined,
        providence_id:
          activity.type !== 'Retorno credor'
            ? activity.providence_id
              ? activity.providence_id
              : activity.id
            : null,
        hubspot_deal_id: activity.hubspot_deal_id || undefined,
        situation: 'Pendente',
        time_init: timeInit,
        time_end: timeEnd,
        manager_id: activity.manager_id || undefined,
      };

      const schema = yup.object().shape({
        title: yup.string().required('Nome obrigatório'),
        //  type: yup.string().required('Tipo obrigatório'),
        time_init: yup.string().required('Início obrigatório'),
        time_end: yup.string().required('Término obrigatório'),
      });

      await schema.validate(body, {
        abortEarly: false,
      });

      const response =
        activityType === 'Retorno credor'
          ? await api.post('/schedule-cs', body)
          : await api.post('/schedule-cs/associated-activity', body);

      const index = activitiesInADay.activities.findIndex(day_hour =>
        isEqual(parseISO(String(day_hour.hour)), parseISO(timeInit)),
      );

      if (index >= 0) {
        activitiesInADay.activities[index].activities.push(response.data);
        setActivitiesInADay(activitiesInADay);
      }

      toast.success('Atividade criada com sucesso!');

      onRequestClose();
      if (updateActivityClose !== undefined) {
        updateActivityClose();
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        Swal.fire(
          'Atenção!',
          'Preencha todos os campos corretamente.',
          'warning',
        );
      } else {
        Swal.fire('Atenção!', error.response.data.message, 'warning');
      }
    }
  }, [
    activitiesInADay,
    activity.hubspot_deal_id,
    activity.id,
    activity.manager_id,
    activity.providence_id,
    activity.type,
    activityType,
    formData.date,
    formData.time_end,
    formData.time_init,
    formData.title,
    observation,
    onRequestClose,
    setActivitiesInADay,
    updateActivityClose,
    user.id,
  ]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={Container}>
      <ContainerModalInfo>
        <Header>Atividade de {activityType}</Header>
        <Line />

        <ContainerInputs>
          <InputFieldModal
            name="title"
            label="Nome"
            placeholder="Inserir nome da atividade"
            onChange={handleInputChange}
          />
          <InputFieldModal
            name="date"
            label="Data:"
            placeholder="__/__/__"
            onChange={handleInputChange}
          />
          <InputContainer>
            <Label>Hora:</Label>
            <InputsTimeContainer>
              <Select
                id="time_init"
                name="time_init"
                defaultValue="Horário inicial"
                onChange={handleInputChange}
              >
                <option value="Horário inicial" disabled>
                  Horário inicial
                </option>

                {intervals.intervalInit &&
                  intervals.intervalInit.map(hour => (
                    <option
                      key={String(hour)}
                      value={format(new Date(hour), 'HH:mm')}
                    >
                      {format(new Date(hour), 'HH:mm')}
                    </option>
                  ))}
              </Select>

              <span id="intervalText">às</span>

              <Select
                id="time_end"
                name="time_end"
                defaultValue="Horário final"
                onChange={handleInputChange}
              >
                <option value="Horário final" disabled>
                  Horário final
                </option>

                {intervals.intervalEnd &&
                  intervals.intervalEnd.map(hour => (
                    <option
                      key={String(hour)}
                      value={format(new Date(hour), 'HH:mm')}
                    >
                      {format(new Date(hour), 'HH:mm')}
                    </option>
                  ))}
              </Select>
            </InputsTimeContainer>
          </InputContainer>
        </ContainerInputs>

        <ContainerButtons>
          <ExitButton type="button" onClick={onRequestClose}>
            Cancelar
          </ExitButton>

          <ConfirmButton onClick={handleSubmit} type="button">
            Confirmar
          </ConfirmButton>
        </ContainerButtons>
      </ContainerModalInfo>
    </Modal>
  );
}
