import { useEffect, useState, useCallback, ChangeEvent } from 'react';

import toast from 'react-hot-toast';
import api from '../../../services/api';

import deleteIcon from '../../../assets/icons/delete.svg';
import editIcon from '../../../assets/icons/edit.svg';
import doubleVerifyIcon from '../../../assets/icons/boubleVerify.svg';

import {
  TableContainer,
  Table,
  Thead,
  TableRow,
  HeadCell,
  Tbody,
  BodyCell,
  PositionInput,
  ButtonDelete,
  ButtonEdit,
  ButtonSave,
} from './styles';
import { confirmModal } from '../../../components/ModalConfirm';

interface IQueue {
  id: string;
  queue_name: string;
  active: boolean;
}
interface ICloserScheduleQueue {
  queue_id: string;
  position: number;
  active: boolean;
}
interface ICloserQueue {
  closer_name: string;
  closer_id: string;
  queues: ICloserScheduleQueue[];
}

interface IEditCloserQueue {
  queue_id: string;
  position: string;
}

export function CloserQueues(): JSX.Element {
  const [queues, setQueues] = useState<IQueue[]>([]);
  const [closersQueues, setClosersQueues] = useState<ICloserQueue[]>([]);
  const [closerName, setCloserName] = useState('');
  const [editQueues, setEditQueues] = useState<IEditCloserQueue[]>([]);
  const [editPosition, setEditPosition] = useState(false);

  useEffect(() => {
    api.get('/schedule/closer-queues').then(response => {
      setClosersQueues(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/schedule/queues').then(response => {
      setQueues(response.data);
    });
  }, []);

  const getPositionQueues = useCallback(
    (queues_info: IQueue[], closer_id: string) => {
      const findByCloserId = closersQueues.find(
        closer_queue => closer_queue.closer_id === closer_id,
      );

      const queueSchedule = queues_info.map(queue_info => {
        const index = findByCloserId.queues.findIndex(
          find_queue => find_queue.queue_id === queue_info.id,
        );

        return {
          queue_id: queue_info.id,
          position:
            index >= 0 && findByCloserId.queues[index].active === true
              ? findByCloserId.queues[index].position
              : '',
          active: queue_info.active,
        };
      });

      return queueSchedule;
    },
    [closersQueues],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      const index = editQueues.findIndex(
        edit_queue => edit_queue.queue_id === name,
      );

      if (index >= 0) {
        editQueues[index] = {
          position: value,
          queue_id: name,
        };

        setEditQueues(editQueues);
      } else {
        editQueues.push({
          position: value,
          queue_id: name,
        });

        setEditQueues(editQueues);
      }
    },
    [editQueues],
  );

  const handleVerifyEditCloserQueue = useCallback((closer_name: string) => {
    setCloserName(closer_name);
    setEditPosition(true);
  }, []);

  const handleEditCoserQueue = useCallback(
    async (closer_id: string) => {
      if (editQueues.length > 0) {
        try {
          await api.put('/schedule/update-closer-queue', {
            closer_id,
            editQueues,
          });

          setEditQueues([]);
          setCloserName('');
          setEditPosition(false);

          toast.success('Queues do closer editadas com sucesso!');
        } catch {
          toast.error('Falha ao editar queues do closer!');
        }
      } else {
        setEditQueues([]);
        setCloserName('');
        setEditPosition(false);
      }
    },
    [editQueues],
  );

  const handleDeleteCloserQueue = useCallback(
    async (closer_id: string) => {
      const confirm = await confirmModal('Tem certeza que deseja excluir?');

      if (confirm) {
        const { data } = await api.put<ICloserScheduleQueue[]>(
          '/schedule/update-closer-queue',
          {
            closer_id,
            editQueues,
          },
        );

        const indexCloserQueue = closersQueues.findIndex(
          closerQueue => closerQueue.closer_id === closer_id,
        );

        data.forEach(updatedQueue => {
          const indexQueue = closersQueues[indexCloserQueue].queues.findIndex(
            foundQueue => foundQueue.queue_id === updatedQueue.queue_id,
          );

          const updatedQueues = closersQueues[indexCloserQueue].queues;

          updatedQueues.splice(indexQueue, 1, updatedQueue);
        });

        setClosersQueues(closersQueues);

        setEditQueues([]);
        setCloserName('');
        setEditPosition(false);
      }
    },
    [closersQueues, editQueues],
  );

  return (
    <TableContainer>
      <Table>
        <Thead>
          <TableRow className="head-row">
            <HeadCell className="closerCell">CLOSER</HeadCell>
            {queues &&
              queues.map(queue =>
                queue.active ? (
                  <HeadCell key={queue.id}>{queue.queue_name}</HeadCell>
                ) : (
                  ''
                ),
              )}
            <HeadCell />
          </TableRow>
        </Thead>

        <Tbody>
          {closersQueues &&
            closersQueues.map(closer_queue => (
              <TableRow className="body-row" key={closer_queue.closer_id}>
                <BodyCell>{closer_queue.closer_name}</BodyCell>

                {getPositionQueues(queues, closer_queue.closer_id).map(
                  queue_schedule =>
                    queue_schedule.active ? (
                      <BodyCell key={queue_schedule.queue_id}>
                        <PositionInput
                          onChange={handleInputChange}
                          name={queue_schedule.queue_id}
                          verifyCloserQueue={
                            closer_queue.closer_name === closerName
                          }
                          disabled={closer_queue.closer_name !== closerName}
                          defaultValue={queue_schedule.position}
                        />
                      </BodyCell>
                    ) : (
                      ''
                    ),
                )}

                <BodyCell className="buttons-cell">
                  {closer_queue.closer_name === closerName ? (
                    <ButtonSave
                      onClick={() =>
                        handleEditCoserQueue(closer_queue.closer_id)
                      }
                    >
                      <img src={doubleVerifyIcon} alt="Save Icon" />
                    </ButtonSave>
                  ) : (
                    <>
                      <ButtonDelete
                        noClickable={
                          editPosition &&
                          closer_queue.closer_name !== closerName
                        }
                        disabled={
                          editPosition &&
                          closer_queue.closer_name !== closerName
                        }
                        onClick={() =>
                          handleDeleteCloserQueue(closer_queue.closer_id)
                        }
                      >
                        <img src={deleteIcon} alt="Delete Icon" />
                      </ButtonDelete>
                      <ButtonEdit
                        noClickable={
                          editPosition &&
                          closer_queue.closer_name !== closerName
                        }
                        disabled={
                          editPosition &&
                          closer_queue.closer_name !== closerName
                        }
                        onClick={() =>
                          handleVerifyEditCloserQueue(closer_queue.closer_name)
                        }
                      >
                        <img src={editIcon} alt="Edit Icon" />
                      </ButtonEdit>
                    </>
                  )}
                </BodyCell>
              </TableRow>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
