import axios from 'axios';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Container } from '../../../components/Container';
import { PaginationComponent } from '../../../components/Pagination';
import { Body } from '../../../components/Styles/Body';
import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../components/Styles/Navigation';
import { Title } from '../../../components/Styles/Title';
import { CampaignTable } from './CampaignTable';
import {
  DivSeparator,
  FileSelectButtonDescription,
  FileContainer,
  FileInputContainer,
  FileInputSpan,
  FileSelectInput,
  FileSendDiv,
  Separator,
  FileSendButton,
  FileSelectLabel,
} from './styles';
import { SmsBatch } from './types/SmsBatch';

export const triggersRoutes = {
  Unipix: '/unipix',
  Zenvia: '/zenvia',
};

interface IFileProps {
  file: File;
  name: string;
  readableSize: string;
}

export function SmsTrigger(): JSX.Element {
  const [batches, setBatches] = useState<SmsBatch[]>([]);
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState([1]);
  const [file, setFile] = useState<IFileProps>(undefined);

  useEffect(() => {
    axios
      .get<any[]>(
        `${process.env.REACT_APP_MARKETING_URL}/sms/campaign/${page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
          },
        },
      )
      .then(({ data }) => {
        const batchArrays = data.map<SmsBatch[]>(campaign => {
          return (campaign.batches as any[]).map<SmsBatch>(batch => {
            return { ...batch, batch: `${campaign.name}-${batch.batch}` };
          });
        });

        const resultBatches: SmsBatch[] = [];

        for (const batchArray of batchArrays) resultBatches.push(...batchArray);

        setBatches(resultBatches);
      })
      .catch(error => {
        if (error.response)
          Swal.fire(
            String(error.response.status),
            error.response.data.message,
            'error',
          );
      });
  }, [page]);

  const getCampaigns = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_MARKETING_URL}/sms/campaign/${page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
          },
        },
      );

      const batchArrays = Array(data).map<SmsBatch[]>(campaign => {
        return Array(campaign.batches).map<SmsBatch>(batch => {
          return { ...batch, batch: `${campaign.name}-${batch.batch}` };
        });
      });

      const resultBatches: SmsBatch[] = [];

      for (const batchArray of batchArrays) resultBatches.push(...batchArray);

      setBatches(resultBatches);
    } catch (error) {
      if (error.response)
        Swal.fire(
          String(error.response.status),
          error.response.data.message,
          'error',
        );
    }
  }, [page]);

  const submitFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const getFile =
      event.target.files === null ? ({} as File) : event.target.files[0];

    const newFile: IFileProps = {
      file: getFile,
      name: getFile.name,
      readableSize: getFile.size.toString(),
    };

    setFile(newFile);

    event.target.value = null;
  }, []);

  const sendCampaignFile = useCallback(async () => {
    const data = new FormData();

    data.append('file', file.file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MARKETING_URL}/sms/campaign`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@Precato:token')}`,
          },
        },
      );

      const batchArrays = Array(response.data).map<SmsBatch[]>(campaign => {
        return Array(campaign.batches).map<SmsBatch>(batch => {
          return { ...batch, batch: `${campaign.name}-${batch.batch}` };
        });
      });

      const resultBatches: SmsBatch[] = [];

      for (const batchArray of batchArrays) resultBatches.push(...batchArray);

      const newBatches = [...resultBatches, ...batches];

      setBatches(newBatches);

      Swal.fire('Sucesso!', 'Campanha cadastrada com sucesso!', 'success');
    } catch (error) {
      Swal.fire(
        String(error.response.status),
        error.response.data.message,
        'error',
      );
    }
  }, [batches, file?.file]);

  const changePage = useCallback(
    (pageNumber: number) => {
      if (pageNumber < 1 || (pageNumber > page && batches.length === 0)) {
        return;
      }

      setPage(pageNumber);

      if (allPages[allPages.length - 1] === pageNumber) {
        setAllPages([...allPages, pageNumber + 1]);
      }
    },
    [page, batches.length, allPages],
  );

  return (
    <Container>
      <Navegation>
        <H1Navigation>Conexão {'>'}</H1Navigation>
        <H1NavigationNow>Disparo SMS</H1NavigationNow>
      </Navegation>

      <Body>
        <Title>Disparo SMS</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>

        <FileContainer>
          <FileInputContainer>
            <FileInputSpan>Arquivo:</FileInputSpan>

            <FileSendDiv>
              <FileSelectLabel htmlFor="file">
                Selecione um arquivo
              </FileSelectLabel>
              <FileSelectInput
                id="file"
                type="file"
                name="file"
                onChange={submitFile}
              />

              <FileSelectButtonDescription>
                {file && file.name ? file.name : 'Nenhum arquivo selecionado'}
              </FileSelectButtonDescription>
            </FileSendDiv>

            <FileSendDiv>
              <FileSendButton
                isActive={!!file}
                onClick={() => sendCampaignFile()}
                disabled={!file}
              >
                Enviar
              </FileSendButton>
            </FileSendDiv>
          </FileInputContainer>
        </FileContainer>

        <FileContainer>
          <CampaignTable batches={batches} onEditCampaign={getCampaigns} />
        </FileContainer>

        <PaginationComponent
          totalPages={allPages}
          page={page}
          handleChangePagination={changePage}
        />
      </Body>
    </Container>
  );
}
