import { ChangeEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as OrderCrescent } from '../../../../assets/icons/polygon_up.svg';
import { ReactComponent as OrderDecrescent } from '../../../../assets/icons/polygon_down.svg';

import {
  Table,
  Thead,
  TableRow,
  Cell,
  ContainerTitle,
  OrderContainerIcon,
  Tbody,
  BoxTable,
  FiltersContainer,
  FilterContent,
  Select,
  BottomBarThead,
  BottomCell,
  BottomContainerTitle,
  AddDescriptionButton,
  CategorySelect,
} from './styles';

import { Body } from '../../../../components/Styles/Body';
import { Container } from '../../../../components/Container';

export interface ICustomerSuccessCases {
  name?: string;
  id: string;
  operacao?: string;
  etapa?: string;
  amount: string;
  pass_date?: string;
  providence_status: string;
  category: string;
  hubspot_deal_id: string;
  observation?: string;
  hasActivities: boolean;
}

interface ICustomerSucess {
  user_id: string;
  type: string;
  user_name: string;
  customerSuccessInfo: ICustomerSuccessCases[];
  handleInputChange: (
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void;
}

export function DealsListComponent({
  type,
  user_id,
  user_name,
  customerSuccessInfo,
  handleInputChange,
}: ICustomerSucess): JSX.Element {
  const categoryArray = ['QUENTE', 'MORNO', 'FRIO', 'AGUARDANDO', 'PERDIDO'];
  const [categoryQuantity, setCategoryQuantity] = useState(0);
  const [categoryAmount, setCategoryAmount] = useState('R$ 0');

  const [currentItems, setCurrentItems] =
    useState<ICustomerSuccessCases[]>(customerSuccessInfo);

  const [isOrder, setIsOrder] = useState(false);

  const history = useHistory();

  const orderInfoCsByAsc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = currentItems.sort(
        (previous: ICustomerSuccessCases, next: ICustomerSuccessCases) => {
          switch (column) {
            case 'name':
              return previous.name > next.name
                ? 1
                : previous.name < next.name
                ? -1
                : 0;
            case 'operation':
              return previous.operacao > next.operacao
                ? 1
                : previous.operacao < next.operacao
                ? -1
                : 0;
            case 'amount':
              return previous.amount > next.amount
                ? 1
                : previous.amount < next.amount
                ? -1
                : 0;
            case 'stage':
              return previous.etapa > next.etapa
                ? 1
                : previous.etapa < next.etapa
                ? -1
                : 0;

            /* case 'date':
              return previous.pass_date > next.pass_date
                ? 1
                : previous.pass_date < next.pass_date
                ? -1
                : 0;
              break; */
            case 'category':
              return previous.etapa > next.etapa
                ? 1
                : previous.etapa < next.etapa
                ? -1
                : 0;
            default:
              return null;
          }
        },
      );

      setCurrentItems([...order]);
    },
    [currentItems, isOrder, setCurrentItems],
  );

  const orderInfoCsByDesc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = currentItems.sort(
        (previous: ICustomerSuccessCases, next: ICustomerSuccessCases) => {
          switch (column) {
            case 'name':
              return previous.name < next.name
                ? 1
                : previous.name > next.name
                ? -1
                : 0;
            case 'operation':
              return previous.operacao < next.operacao
                ? 1
                : previous.operacao > next.operacao
                ? -1
                : 0;
            case 'amount':
              return previous.amount < next.amount
                ? 1
                : previous.amount > next.amount
                ? -1
                : 0;
            case 'stage':
              return previous.etapa < next.etapa
                ? 1
                : previous.etapa > next.etapa
                ? -1
                : 0;
            /* case 'date':
              return previous.pass_date < next.pass_date
                ? 1
                : previous.pass_date > next.pass_date
                ? -1
                : 0;
              break; */
            case 'category':
              return previous.etapa < next.etapa
                ? 1
                : previous.etapa > next.etapa
                ? -1
                : 0;

            default:
              return null;
          }
        },
      );

      setCurrentItems([...order]);
    },
    [currentItems, isOrder, setCurrentItems],
  );

  const handleSelectChangeStage = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      if (value === '') {
        setCurrentItems(customerSuccessInfo);
      } else {
        const filteredItems = customerSuccessInfo.filter(filterInfo => {
          return filterInfo.etapa === value;
        });

        setCurrentItems(filteredItems);
      }
    },
    [customerSuccessInfo],
  );

  const handleSelectChangeProvidenceStatus = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      if (value === '') {
        setCurrentItems(customerSuccessInfo);
      } else {
        const filteredItems = customerSuccessInfo.filter(filterInfo => {
          return filterInfo.providence_status === value;
        });

        setCurrentItems(filteredItems);
      }
    },
    [customerSuccessInfo],
  );

  const handleSelectChangeCategory = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      if (value === '') {
        setCurrentItems(customerSuccessInfo);
      } else {
        const filteredItems = customerSuccessInfo.filter(filterInfo => {
          return filterInfo.category === value;
        });

        setCurrentItems(filteredItems);
      }
    },
    [customerSuccessInfo],
  );

  const handleSelectCountCategory = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      if (value === '') {
        setCategoryQuantity(0);
        setCategoryAmount('R$ 0');
      } else {
        const filteredItems = currentItems.filter(filterInfo => {
          return filterInfo.category === value;
        });

        setCategoryQuantity(filteredItems.length);

        let amountTotal = 0;
        for (const cases of filteredItems) {
          amountTotal = Number(cases.amount) + amountTotal;
        }

        const formatAmount = new Intl.NumberFormat('pt-BR', {
          style: 'currency',

          currency: 'BRL',
        }).format(Number(amountTotal));
        setCategoryAmount(formatAmount);
      }
    },
    [currentItems],
  );

  const handleFormatAmount = useCallback(value => {
    const formatAmount = new Intl.NumberFormat('pt-BR', {
      style: 'currency',

      currency: 'BRL',
    }).format(Number(value));

    return formatAmount;
  }, []);

  const handleRedirectToIndividualCsHistory = useCallback(
    (customerSuccess: ICustomerSuccessCases) => {
      history.push({
        pathname: '/payments/schedule/manager-resume-painel',
        state: {
          user_id: customerSuccess.id,
          id: user_id,
          hubspot_deal_id: customerSuccess.hubspot_deal_id,
          type,
          user_name,
          cs_name: customerSuccess.name,
          amount: customerSuccess.amount,
          category: customerSuccess.category,
          operation: customerSuccess.operacao,
          stage: customerSuccess.etapa,
          observation: customerSuccess.observation,
        },
      });
    },
    [history, user_id, user_name, type],
  );

  return (
    <Container>
      <Body>
        <FiltersContainer>
          <FilterContent>
            <span>Filtrar tag's de providência</span>
            <Select
              defaultValue=""
              onChange={handleSelectChangeProvidenceStatus}
            >
              <option value="">Selecione a opção</option>
              <option value="1">Providências a vencer (24hrs)</option>
              <option value="2">Providências atrasadas (-5 dias)</option>
              <option value="3">Providências atrasadas (+5 dias)</option>
              <option value="Atuação imediata">Atuação imediata</option>
            </Select>
          </FilterContent>

          <FilterContent>
            <span>Filtrar etapa</span>
            <Select defaultValue="" onChange={handleSelectChangeStage}>
              <option value="">Selecione a opção</option>
              <option value="Aguardando">Aguardando</option>
              <option value="FECHADO">Fechado</option>
              <option value="Em Andamento">Em andamento</option>
              <option value="Análise jurídica">Análise jurídica</option>
              <option value="Assinado">Assinado</option>
              <option value="Cartório agêndado">Cartório agêndado</option>
              <option value="Checkpoint">Checkpoint</option>
              <option value="Liberado para assinar">
                Liberado para assinar
              </option>
              <option value="Novo negócio">Novo negócio</option>
              <option value="Pagamento">Pagamento</option>
            </Select>
          </FilterContent>

          <FilterContent>
            <span>Filtrar categoria</span>
            <Select defaultValue="" onChange={handleSelectChangeCategory}>
              <option value="">Selecione a opção</option>
              <option value="QUENTE">QUENTE</option>
              <option value="MORNO">MORNO</option>
              <option value="FRIO">FRIO</option>
              <option value="AGUARDANDO">AGUARDANDO</option>
            </Select>
          </FilterContent>
        </FiltersContainer>
        <BoxTable>
          <Table>
            <Thead>
              <TableRow providenceStatus="Default">
                <Cell
                  onClick={() =>
                    isOrder
                      ? orderInfoCsByAsc('name')
                      : orderInfoCsByDesc('name')
                  }
                  style={{ borderRight: '1px solid #9d9d9d' }}
                  hasActivities
                >
                  <ContainerTitle>Nome do credor</ContainerTitle>
                  <OrderContainerIcon>
                    <OrderCrescent />
                    <OrderDecrescent />
                  </OrderContainerIcon>
                </Cell>

                <Cell
                  onClick={() =>
                    isOrder
                      ? orderInfoCsByAsc('operation')
                      : orderInfoCsByDesc('operation')
                  }
                  style={{ borderRight: '1px solid #9d9d9d' }}
                  hasActivities
                >
                  <ContainerTitle>Operação</ContainerTitle>
                  <OrderContainerIcon>
                    <OrderCrescent />
                    <OrderDecrescent />
                  </OrderContainerIcon>
                </Cell>

                <Cell
                  onClick={() =>
                    isOrder
                      ? orderInfoCsByAsc('amount')
                      : orderInfoCsByDesc('amount')
                  }
                  style={{ borderRight: '1px solid #9d9d9d' }}
                  hasActivities
                >
                  <ContainerTitle>Valor</ContainerTitle>
                  <OrderContainerIcon>
                    <OrderCrescent />
                    <OrderDecrescent />
                  </OrderContainerIcon>
                </Cell>

                <Cell
                  onClick={() =>
                    isOrder
                      ? orderInfoCsByAsc('stage')
                      : orderInfoCsByDesc('stage')
                  }
                  style={{ borderRight: '1px solid #9d9d9d' }}
                  hasActivities
                >
                  <ContainerTitle>Etapa</ContainerTitle>
                  <OrderContainerIcon>
                    <OrderCrescent />
                    <OrderDecrescent />
                  </OrderContainerIcon>
                </Cell>

                <Cell
                  onClick={() =>
                    isOrder
                      ? orderInfoCsByAsc('category')
                      : orderInfoCsByDesc('category')
                  }
                  hasActivities
                >
                  <ContainerTitle>Categoria</ContainerTitle>
                  <OrderContainerIcon>
                    <OrderCrescent />
                    <OrderDecrescent />
                  </OrderContainerIcon>
                </Cell>
              </TableRow>
            </Thead>
            <Tbody>
              {currentItems &&
                currentItems.map(customer_sucess => (
                  <TableRow
                    key={customer_sucess.id}
                    providenceStatus={customer_sucess.providence_status}
                  >
                    <Cell
                      onClick={() =>
                        handleRedirectToIndividualCsHistory(customer_sucess)
                      }
                      hasActivities={customer_sucess.hasActivities}
                    >
                      <span>{customer_sucess.name}</span>
                    </Cell>
                    <Cell
                      onClick={() =>
                        handleRedirectToIndividualCsHistory(customer_sucess)
                      }
                      hasActivities={customer_sucess.hasActivities}
                    >
                      <span>{customer_sucess.operacao}</span>
                    </Cell>
                    <Cell
                      onClick={() =>
                        handleRedirectToIndividualCsHistory(customer_sucess)
                      }
                      hasActivities={customer_sucess.hasActivities}
                    >
                      {handleFormatAmount(customer_sucess.amount)}
                    </Cell>
                    <Cell
                      onClick={() =>
                        handleRedirectToIndividualCsHistory(customer_sucess)
                      }
                      hasActivities={customer_sucess.hasActivities}
                    >
                      {customer_sucess.etapa}
                    </Cell>

                    {/* <Cell>{customer_sucess.pass_date}</Cell> */}

                    <Cell hasActivities={customer_sucess.hasActivities}>
                      <CategorySelect
                        name="category_field"
                        className="larger"
                        defaultValue="Todos"
                        onChange={handleInputChange}
                      >
                        <option value="Todos">
                          {customer_sucess.category}
                        </option>
                        {categoryArray &&
                          categoryArray.map(category => (
                            <option
                              value={`${category.toUpperCase()};${
                                customer_sucess.hubspot_deal_id
                              }`}
                            >
                              {category}
                            </option>
                          ))}
                      </CategorySelect>
                    </Cell>

                    {customer_sucess.observation !== undefined ? (
                      <AddDescriptionButton>
                        <button type="button">!</button>
                      </AddDescriptionButton>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))}
            </Tbody>
          </Table>
        </BoxTable>
        <BoxTable>
          <BottomBarThead>
            <BottomCell style={{ borderRight: '1px solid #9d9d9d' }}>
              <BottomContainerTitle>
                Selecione a categoria:
              </BottomContainerTitle>
              <Select defaultValue="" onChange={handleSelectCountCategory}>
                <option value="">Selecione a opção</option>

                <option value="">Selecione a opção</option>
                <option value="QUENTE">QUENTE</option>
                <option value="MORNO">MORNO</option>
                <option value="FRIO">FRIO</option>
                <option value="AGUARDANDO">AGUARDANDO</option>
                <option value="PERDIDO">PERDIDO</option>
              </Select>
            </BottomCell>
            <BottomCell style={{ borderRight: '1px solid #9d9d9d' }}>
              <BottomContainerTitle>Total de casos: </BottomContainerTitle>
              <span>{categoryQuantity !== 0 ? categoryQuantity : '-'}</span>
            </BottomCell>
            <BottomCell>
              <BottomContainerTitle>Valor total: </BottomContainerTitle>
              <span>{categoryAmount !== 'R$ 0' ? categoryAmount : 'R$ 0'}</span>
            </BottomCell>
          </BottomBarThead>
        </BoxTable>
      </Body>
    </Container>
  );
}
