import { useState } from 'react';
import { Container } from '../../components/Container';
import { Body } from '../../components/Styles/Body';
import { CloserQueues } from './CloserQueues';
import { CreateQueues } from './CreateQueue';

import {
  Navegation,
  H1Navigation,
  H1NavigationNow,
} from '../../components/Styles/Navigation';
import { TitleContainer, Separator } from './styles';

export function Queues(): JSX.Element {
  const [selectHistory, setSelectHistory] = useState<string>('Closers');

  return (
    <Container>
      <Navegation>
        <H1Navigation>Administração {'>'}</H1Navigation>
        <H1NavigationNow>Queues</H1NavigationNow>
      </Navegation>
      <Body>
        <TitleContainer selectHistory={selectHistory}>
          <button
            type="button"
            className="closer-history"
            onClick={() => setSelectHistory('Closers')}
          >
            Closer
            <Separator />
          </button>

          <button
            type="button"
            className="queue-history"
            onClick={() => setSelectHistory('Queues')}
          >
            Queues
            <Separator />
          </button>
        </TitleContainer>

        {selectHistory === 'Closers' ? <CloserQueues /> : <CreateQueues />}
      </Body>
    </Container>
  );
}
