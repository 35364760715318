import { ChangeEvent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
  CampaignModalContainer,
  CampaignNameInput,
  ConfigContainer,
  FieldName,
  OptionContainer,
  SaveButton,
  ScheduleContainer,
  SchedulingInfoInput,
  Separator,
  Title,
} from './styles';

interface ICampaignModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  campaign_name: string;
  scheduled_date: string;
  triggers_count: number;
  batch_id: string;
}

export function CampaignModal({
  isOpen,
  onRequestClose,
  campaign_name,
  scheduled_date,
  triggers_count,
  batch_id,
}: ICampaignModalProps): JSX.Element {
  const [scheduleDate, setScheduleDate] = useState('');
  const [costCenter, setCostCenter] = useState<string>(undefined);
  const [product, setProduct] = useState<string>(undefined);
  const [company, setCompany] = useState<string>(undefined);

  const handleScheduleDateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setScheduleDate(event.target.value);
    },
    [],
  );

  const changeCostCenter = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCostCenter(event.target.value);
    },
    [],
  );

  const changeProduct = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setProduct(event.target.value);
  }, []);

  const changeCompany = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCompany(event.target.value);
  }, []);

  const saveScheduling = useCallback(async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_MARKETING_URL}/sms/batch/scheduling`,
        {
          id: batch_id,
          scheduling_date: new Date(scheduleDate),
          company: company.toLowerCase(),
          cost_center: costCenter.toUpperCase(),
          product,
        },
      );

      Swal.fire('Sucesso!', 'Disparo agendado!', 'success');
    } catch (error) {
      if (error.response)
        Swal.fire(
          String(error.response.status),
          error.response.data.message,
          'error',
        );
    }
  }, [batch_id, company, costCenter, product, scheduleDate]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={CampaignModalContainer}
    >
      <Title>Campanha</Title>
      <Separator />

      <FieldName>Nome da campanha:</FieldName>
      <CampaignNameInput readOnly value={campaign_name} />

      <FieldName>Empresa de disparo:</FieldName>
      <CampaignNameInput
        id="company"
        name="company"
        onChange={changeCompany}
        readOnly={!!scheduled_date}
        defaultValue={company}
      />

      <ConfigContainer>
        <ScheduleContainer>
          <OptionContainer>
            <FieldName>Data de disparo</FieldName>
            <SchedulingInfoInput
              id="schedule_date"
              name="scheduled_date"
              type="datetime-local"
              onChange={handleScheduleDateChange}
              defaultValue={
                scheduled_date && new Date(scheduled_date).toISOString()
              }
              readOnly={!!scheduled_date}
            />

            <FieldName>Centro de custo</FieldName>
            <SchedulingInfoInput
              id="cost_center"
              name="cost_center"
              onChange={changeCostCenter}
              defaultValue={costCenter}
              readOnly={!!scheduled_date}
            />
          </OptionContainer>

          <OptionContainer>
            <FieldName>N° de disparos</FieldName>
            <SchedulingInfoInput readOnly value={triggers_count} />

            <FieldName>Produto</FieldName>
            <SchedulingInfoInput
              id="product"
              name="product"
              onChange={changeProduct}
              defaultValue={product}
              readOnly={!!scheduled_date}
            />
          </OptionContainer>
        </ScheduleContainer>
      </ConfigContainer>

      <SaveButton onClick={saveScheduling}>Salvar</SaveButton>
    </Modal>
  );
}
