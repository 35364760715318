import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ReactComponent as OrderCrescent } from '../../../../../assets/icons/polygon_up.svg';
import { ReactComponent as OrderDecrescent } from '../../../../../assets/icons/polygon_down.svg';
import checkTaskIcon from '../../../../../assets/icons/check_task.svg';
import checkedTaskIcon from '../../../../../assets/icons/checked_task.svg';
import {
  HeaderWraper,
  HeadContainer,
  Container,
  TableRow,
  Thead,
  Cell,
  CellAct,
  Tbody,
} from './styles';
import api from '../../../../../services/api';
import { OrderContainerIcon } from '../styles';

interface IProvidencesInfo {
  id: string;
  hubspot_deal_id: string;
  name: string;
  conclusion_prev: string;
  status: string;
}
interface IActivitiesInfo {
  hubspot_deal_id: string;
  date: string;
  hour: string;
  name: string;
  conclusion_prev: string;
  status: string;
}
interface ListingProvidencesComponent {
  componentChooser: string;
  hubspot_deal_id: string;
  type: string;
}

export function ListingProvidencesComponent({
  componentChooser,
  hubspot_deal_id,
  type,
}: ListingProvidencesComponent): JSX.Element {
  const [isOrder, setIsOrder] = useState(false);

  const [providencesInfo, setProvidencesInfo] = useState<IProvidencesInfo[]>([
    {
      id: '',
      name: ' - ',
      hubspot_deal_id: ' ',
      status: ' - ',
      conclusion_prev: '-',
    },
  ] as IProvidencesInfo[]);

  const [activitiesInfo, setActivitiesInfo] = useState<IActivitiesInfo[]>([
    {
      name: ' - ',
      hubspot_deal_id: ' ',
      status: ' - ',
      conclusion_prev: '-',
      date: '-',
      hour: '-',
    },
  ] as IActivitiesInfo[]);

  useEffect(() => {
    api
      .get(`/schedule-cs/all-providences?hubspot_deal_id=${hubspot_deal_id}`)
      .then(response => {
        setProvidencesInfo(response.data);
      });
  }, [hubspot_deal_id]);

  useEffect(() => {
    api
      .get(`/schedule-cs/all-activities?hubspot_deal_id=${hubspot_deal_id}`)
      .then(response => {
        setActivitiesInfo(response.data);
      });
  }, [hubspot_deal_id]);

  const orderActivitiesCsByAsc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = activitiesInfo.sort(
        (previous: IActivitiesInfo, next: IActivitiesInfo) => {
          switch (column) {
            case 'name':
              return previous.name > next.name
                ? 1
                : previous.name < next.name
                ? -1
                : 0;
              break;
            case 'date':
              return previous.date > next.date
                ? 1
                : previous.date < next.date
                ? -1
                : 0;
              break;
            case 'hour':
              return previous.hour > next.hour
                ? 1
                : previous.hour < next.hour
                ? -1
                : 0;
              break;
            case 'status':
              return previous.status > next.status
                ? 1
                : previous.status < next.status
                ? -1
                : 0;
              break;
            case 'conclusion':
              return previous.conclusion_prev > next.conclusion_prev
                ? 1
                : previous.conclusion_prev < next.conclusion_prev
                ? -1
                : 0;
              break;

            default:
              return null;
              break;
          }
        },
      );

      setActivitiesInfo([...order]);
    },
    [activitiesInfo, isOrder],
  );

  const orderActivitiesCsByDesc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = activitiesInfo.sort(
        (previous: IActivitiesInfo, next: IActivitiesInfo) => {
          switch (column) {
            case 'name':
              return previous.name < next.name
                ? 1
                : previous.name > next.name
                ? -1
                : 0;
              break;
            case 'date':
              return previous.date < next.date
                ? 1
                : previous.date > next.date
                ? -1
                : 0;
              break;
            case 'hour':
              return previous.hour < next.hour
                ? 1
                : previous.hour > next.hour
                ? -1
                : 0;
              break;
            case 'status':
              return previous.status < next.status
                ? 1
                : previous.status > next.status
                ? -1
                : 0;
              break;
            case 'conclusion':
              return previous.conclusion_prev > next.conclusion_prev
                ? 1
                : previous.conclusion_prev < next.conclusion_prev
                ? -1
                : 0;
              break;

            default:
              return null;
              break;
          }
        },
      );

      setActivitiesInfo([...order]);
    },
    [activitiesInfo, isOrder],
  );

  const orderProvidencesCsByAsc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = providencesInfo.sort(
        (previous: IProvidencesInfo, next: IProvidencesInfo) => {
          switch (column) {
            case 'name':
              return previous.name > next.name
                ? 1
                : previous.name < next.name
                ? -1
                : 0;
              break;
            case 'conclusion':
              return previous.conclusion_prev > next.conclusion_prev
                ? 1
                : previous.conclusion_prev < next.conclusion_prev
                ? -1
                : 0;
              break;
            case 'status':
              return previous.status > next.status
                ? 1
                : previous.status < next.status
                ? -1
                : 0;
              break;

            default:
              return null;
              break;
          }
        },
      );

      setProvidencesInfo([...order]);
    },
    [isOrder, providencesInfo],
  );

  const orderProvidencesCsByDesc = useCallback(
    (column: string) => {
      setIsOrder(!isOrder);
      const order = providencesInfo.sort(
        (previous: IProvidencesInfo, next: IProvidencesInfo) => {
          switch (column) {
            case 'name':
              return previous.name < next.name
                ? 1
                : previous.name > next.name
                ? -1
                : 0;
              break;
            case 'conclusion':
              return previous.conclusion_prev < next.conclusion_prev
                ? 1
                : previous.conclusion_prev > next.conclusion_prev
                ? -1
                : 0;
              break;
            case 'status':
              return previous.status < next.status
                ? 1
                : previous.status > next.status
                ? -1
                : 0;
              break;

            default:
              return null;
              break;
          }
        },
      );

      setProvidencesInfo([...order]);
    },
    [isOrder, providencesInfo],
  );

  const handleConcludeProvidence = useCallback(
    async (providence: IProvidencesInfo) => {
      try {
        await api.patch('/schedule-cs/providence', {
          providence_id: providence.id,
          situation: 'Concluída',
        });

        api
          .get(
            `/schedule-cs/all-providences?hubspot_deal_id=${hubspot_deal_id}`,
          )
          .then(response => {
            setProvidencesInfo(response.data);
          });

        toast.success('Atividade atualizada com sucesso!');
      } catch (error) {
        toast.error('Falha ao atualizar a tarefa!');
      }
    },
    [hubspot_deal_id],
  );

  return (
    <Container>
      {componentChooser === 'Providências' ? (
        <>
          <HeaderWraper>
            <HeadContainer>PROVIDÊNCIAS</HeadContainer>
          </HeaderWraper>

          <Thead>
            <TableRow>
              <Cell
                onClick={() =>
                  isOrder
                    ? orderProvidencesCsByAsc('name')
                    : orderProvidencesCsByDesc('name')
                }
                style={{ borderRight: '1px solid #9d9d9d' }}
              >
                PROVIDÊNCIA
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </Cell>
              <Cell
                onClick={() =>
                  isOrder
                    ? orderProvidencesCsByAsc('status')
                    : orderProvidencesCsByDesc('status')
                }
                style={{ borderRight: '1px solid #9d9d9d' }}
              >
                STATUS
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </Cell>
              <Cell
                onClick={() =>
                  isOrder
                    ? orderProvidencesCsByAsc('conclusion')
                    : orderProvidencesCsByDesc('conclusion')
                }
              >
                PREVISÃO DE CONCLUSÃO
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </Cell>
              {type !== 'Manager' ? <Cell /> : ''}
            </TableRow>
          </Thead>
          {providencesInfo &&
            providencesInfo.map(providence => (
              <Tbody providenceStatus={providence.status}>
                <TableRow key={providence.hubspot_deal_id}>
                  <Cell>{providence.name}</Cell>

                  <Cell>{providence.status}</Cell>

                  <Cell>{providence.conclusion_prev}</Cell>
                  {type !== 'Manager' ? (
                    <Cell>
                      <img
                        onClick={() => handleConcludeProvidence(providence)}
                        src={
                          providence.status === 'Concluída'
                            ? checkedTaskIcon
                            : checkTaskIcon
                        }
                        alt="Concluir tarefa"
                      />
                    </Cell>
                  ) : (
                    ''
                  )}
                </TableRow>
              </Tbody>
            ))}
        </>
      ) : type === 'Manager' ? (
        <>
          <HeaderWraper>
            <HeadContainer>ATIVIDADES</HeadContainer>
          </HeaderWraper>

          <Thead>
            <TableRow>
              <CellAct
                onClick={() =>
                  isOrder
                    ? orderActivitiesCsByAsc('date')
                    : orderActivitiesCsByDesc('date')
                }
                style={{
                  borderRight: '1px solid #9d9d9d',
                }}
              >
                DATA
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </CellAct>
              <CellAct
                onClick={() =>
                  isOrder
                    ? orderActivitiesCsByAsc('hour')
                    : orderActivitiesCsByDesc('hour')
                }
                style={{ borderRight: '1px solid #9d9d9d' }}
              >
                HORA
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </CellAct>

              <CellAct
                onClick={() =>
                  isOrder
                    ? orderActivitiesCsByAsc('name')
                    : orderActivitiesCsByDesc('name')
                }
                style={{ borderRight: '1px solid #9d9d9d' }}
              >
                ATIVIDADE
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </CellAct>
              <CellAct
                onClick={() =>
                  isOrder
                    ? orderActivitiesCsByAsc('status')
                    : orderActivitiesCsByDesc('status')
                }
                style={{ borderRight: '1px solid #9d9d9d' }}
              >
                STATUS
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </CellAct>
              <CellAct
                onClick={() =>
                  isOrder
                    ? orderActivitiesCsByAsc('conclusion')
                    : orderActivitiesCsByDesc('conclusion')
                }
              >
                PREVISÃO DE CONCLUSÃO
                <OrderContainerIcon>
                  <OrderCrescent />
                  <OrderDecrescent />
                </OrderContainerIcon>
              </CellAct>
            </TableRow>
          </Thead>
          {activitiesInfo &&
            activitiesInfo.map(activities => (
              <Tbody providenceStatus={activities.status}>
                <TableRow key={activities.hubspot_deal_id}>
                  <CellAct>{activities.date}</CellAct>
                  <CellAct>{activities.hour}</CellAct>
                  <CellAct>{activities.name}</CellAct>

                  <CellAct>{activities.status}</CellAct>

                  <CellAct>{activities.conclusion_prev}</CellAct>
                </TableRow>
              </Tbody>
            ))}
        </>
      ) : (
        ''
      )}
    </Container>
  );
}
