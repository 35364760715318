import { ChangeEvent, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { ReactComponent as ArrowBlue } from '../../../../../assets/icons/arrow_blue.svg';

import {
  H1Navigation,
  H1NavigationNow,
  Navegation,
} from '../../../../../components/Styles/Navigation';
import { Title } from '../../Title';
import { Body } from '../../../../../components/Styles/Body';
import { Container } from '../../../../../components/Container';

import {
  Main,
  ActivitiesContainer,
  TitleBackButtonContainer,
  TitleContainer,
  BackButtonContainer,
  Icon,
  GoBackLink,
  InfoRow,
  InfoColumn,
  InfoTitle,
  InfoAnswer,
  InfoObsField,
  PageSelector,
  Button,
  PageSelectorAct,
} from './styles';
import { ListingProvidencesComponent } from '../ListingProvidencesComponent';
import api from '../../../../../services/api';
import { Link } from '../../ModalUpdateActivity/styles';

interface ICustomerSucess {
  user_id: string;
  id: string;
  type: string;
  user_name: string;
  cs_name: string;
  amount: number;
  category: string;
  operation: string;
  stage: string;
  date: Date;
  hubspot_deal_id: string;
  observation: string;
}

interface IObservationField {
  observation_field: string;
  category_field: string;
  hubspot_deal_id: string;
}
export function ManagerResumePainel(): JSX.Element {
  const [componentChooser, setComponentChooser] = useState('Providências');
  const [observationComponent, setObservationComponent] =
    useState<IObservationField>({} as IObservationField);

  const history = useHistory();

  const location = useLocation();

  const csInfo = location.state as ICustomerSucess;

  const handleRedirectToIndividualCsHistory = useCallback(() => {
    if (csInfo.type === 'Manager') {
      history.push({
        pathname: '/payments/schedule/customer-sucess/schedule-cases-listing',
        state: {
          user_id: csInfo.user_id,
          type: 'Manager',
          user_name: csInfo.user_name,
          sucess: csInfo.hubspot_deal_id,
        },
      });
    } else {
      history.push({
        pathname: '/payments/schedule/customer-success',
        state: {
          filter: 'deals',
          user_id: csInfo.user_id,
        },
      });
    }
  }, [history, csInfo]);

  const handleFormatAmount = useCallback(value => {
    const formatFinalAmout = new Intl.NumberFormat('pt-BR', {
      style: 'currency',

      currency: 'BRL',
    }).format(Number(value));

    return formatFinalAmout;
  }, []);

  const handleComponentChooser = useCallback(component => {
    setComponentChooser(component);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      await api.post('/schedule-cs/update-deal-cache', {
        user_id: csInfo.user_id,
        category_cache: {
          hubspot_deal_id: csInfo.hubspot_deal_id,
          category: observationComponent.category_field,
          observation: observationComponent.observation_field,
        },
      });

      toast.success('Envio confirmado com sucesso!');
    } catch (error) {
      if (error.response.data.status === 400) {
        Swal.fire('Erro!', error.response.data.message, 'error');
      }
    }
  }, [observationComponent, csInfo]);

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = event.target;
      if (name === 'category_field' && value === 'Todos') {
        setObservationComponent({
          ...observationComponent,
          [name]: csInfo.category,
        });
      } else {
        setObservationComponent({
          ...observationComponent,
          [name]: value,
        });
      }
    },

    [csInfo.category, observationComponent],
  );

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'} </H1Navigation>
        <H1Navigation>Agenda {'>'} </H1Navigation>
        <H1NavigationNow>CS</H1NavigationNow>
      </Navegation>
      <Body>
        {csInfo ? (
          <TitleBackButtonContainer>
            <BackButtonContainer>
              <GoBackLink onClick={handleRedirectToIndividualCsHistory}>
                <Icon>
                  <ArrowBlue />
                </Icon>
                Voltar
              </GoBackLink>
            </BackButtonContainer>

            <TitleContainer>
              <Title
                name="Casos - "
                nameStrong={csInfo.user_name}
                isShared={false}
                separatorWidth={0}
              />
            </TitleContainer>
          </TitleBackButtonContainer>
        ) : (
          <Title
            name="Casos"
            nameStrong=""
            isShared={false}
            separatorWidth={0}
          />
        )}

        <Main>
          <InfoRow>
            <InfoColumn>
              <InfoTitle>
                Credor: <InfoAnswer>{csInfo.cs_name}</InfoAnswer>
              </InfoTitle>
              <InfoTitle>
                Operação: <InfoAnswer>{csInfo.operation}</InfoAnswer>
              </InfoTitle>
              <InfoTitle>
                Valor:{' '}
                <InfoAnswer>{handleFormatAmount(csInfo.amount)}</InfoAnswer>
              </InfoTitle>
            </InfoColumn>
            <InfoColumn>
              <InfoTitle>
                Etapa: <InfoAnswer>{csInfo.stage}</InfoAnswer>
              </InfoTitle>
              <InfoTitle>
                Link:{' '}
                <InfoAnswer>
                  <Link
                    href={`https://app.hubspot.com/contacts/7857327/deal/${csInfo.hubspot_deal_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`${csInfo.hubspot_deal_id}`}
                  </Link>
                </InfoAnswer>
              </InfoTitle>

              <InfoTitle>
                Categoria: <InfoAnswer>{csInfo.category}</InfoAnswer>
              </InfoTitle>
            </InfoColumn>
            <InfoColumn>
              <InfoTitle>Observações: </InfoTitle>
              <InfoObsField
                name="observation_field"
                onChange={handleInputChange}
                defaultValue={csInfo.observation}
                disabled={csInfo.type !== 'Manager'}
              />
              {csInfo.type === 'Manager' ? (
                <Button onClick={handleSubmit}> Salvar</Button>
              ) : (
                ''
              )}
            </InfoColumn>
          </InfoRow>

          <ActivitiesContainer>
            {csInfo.type === 'Manager' ? (
              <>
                <PageSelector
                  onClick={() => handleComponentChooser('Providências')}
                  providenceStatus={componentChooser}
                  className="first"
                  id="first"
                >
                  Providências
                </PageSelector>
                <PageSelectorAct
                  onClick={() => handleComponentChooser('Atividades')}
                  providenceStatus={componentChooser}
                >
                  Atividades
                </PageSelectorAct>
              </>
            ) : (
              () => handleComponentChooser('Providências')
            )}
          </ActivitiesContainer>

          <ListingProvidencesComponent
            type={csInfo.type}
            componentChooser={componentChooser}
            hubspot_deal_id={csInfo.hubspot_deal_id}
          />
        </Main>
      </Body>
    </Container>
  );
}
