import styled, { css } from 'styled-components';

interface QueueInputProps {
  verifyQueueName: boolean;
}

interface ButtonsProps {
  noClickable: boolean;
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TableContainer = styled.div`
  width: 68.75rem;

  overflow-x: auto;

  @media (min-width: 1440px) {
    width: 100%;
  }
`;

export const Table = styled.table`
  width: 100rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Thead = styled.thead`
  width: 100%;
  background: var(--blue-100);

  border-radius: 0.25rem;
  display: flex;
  align-items: center;

  .head-row {
    height: 2.5rem;
  }
`;

export const TableRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.25rem;

  width: 100%;

  .buttons-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  th:nth-child(1) {
    width: 6.82%;
  }
  th:nth-child(2) {
    width: 9.4%;
  }
  th:nth-child(3) {
    width: 9.81%;
  }
  th:nth-child(4) {
    width: 9.81%;
  }
  th:nth-child(5) {
    width: 5.11%;
  }
  th:nth-child(6) {
    width: 5.11%;
  }
  th:nth-child(7) {
    width: 5.11%;
  }
  th:nth-child(8) {
    width: 5.11%;
  }
  th:nth-child(9) {
    width: 5.11%;
  }
  th:nth-child(10) {
    width: 5.97%;
  }
  th:nth-child(11) {
    width: 5.97%;
  }
  th:nth-child(12) {
    width: 5.97%;
  }
  th:nth-child(13) {
    width: 6.82%;
  }
  th:nth-child(14) {
    width: 6.82%;
  }
  th:nth-child(15) {
    width: 6.82%;
  }

  td:nth-child(1) {
    width: 6.82%;
  }
  td:nth-child(2) {
    width: 9.4%;
  }
  td:nth-child(3) {
    width: 9.81%;
  }
  td:nth-child(4) {
    width: 9.81%;
  }
  td:nth-child(5) {
    width: 5.11%;
  }
  td:nth-child(6) {
    width: 5.11%;
  }
  td:nth-child(7) {
    width: 5.11%;
  }
  td:nth-child(8) {
    width: 5.11%;
  }
  td:nth-child(9) {
    width: 5.11%;
  }
  td:nth-child(10) {
    width: 5.97%;
  }
  td:nth-child(11) {
    width: 5.97%;
  }
  td:nth-child(12) {
    width: 5.97%;
  }
  td:nth-child(13) {
    width: 6.82%;
  }
  td:nth-child(14) {
    width: 6.82%;
  }
  td:nth-child(15) {
    width: 6.82%;
  }
`;

export const HeadCell = styled.th`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1.563rem;
  gap: 0.25rem;

  font-weight: 600;
  font-size: 1rem;
  text-align: center;

  color: var(--blue-700);

  border-right: 0.063rem solid var(--blue-700);

  &:nth-child(15) {
    border-right: none;
  }
`;

export const Tbody = styled.tbody`
  width: 100%;

  .body-row {
    height: 1.563rem;
  }

  tr:nth-child(odd) {
    background: var(--gray-100);
  }

  tr:nth-child(even) {
    background: var(--gray-50);
  }
`;

export const BodyCell = styled.td`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .checkbox-cell {
    width: 0.938rem;
    height: 0.938rem;
    cursor: default;
  }
`;

export const QueueInput = styled.input<QueueInputProps>`
  width: 90%;
  height: 1.3rem;

  background: none;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 0.75rem;
  text-align: center;

  ${props =>
    props.verifyQueueName
      ? css`
          border-radius: 0.25rem;
          border: 1px solid var(--blue-700);
          cursor: text;
        `
      : css`
          border: none;
          cursor: default;
        `}
`;

export const ButtonDelete = styled.button<ButtonsProps>`
  height: 1.125rem;
  width: 1.125rem;

  img {
    height: 1.125rem;
    width: 1.125rem;
  }

  border: none;
  border-radius: 0.313rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.noClickable &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
`;

export const ButtonEdit = styled.button<ButtonsProps>`
  height: 1.125rem;
  width: 1.125rem;

  background: var(--blue-600);
  border: none;
  border-radius: 0.313rem;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.noClickable &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
`;

export const CreateQueueButton = styled.button<ButtonsProps>`
  width: 15rem;
  height: 2.75rem;
  color: var(--white);

  font-weight: 700;
  font-size: 1.125rem;
  text-align: center;

  background: var(--green-600);
  border-radius: 0.5rem;
  border: none;
  margin-top: 1.938rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  ${props =>
    props.noClickable &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
`;

export const ButtonSave = styled.button`
  img {
    height: 1.125rem;
    width: 1.125rem;
  }

  border: none;
  border-radius: 0.313rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
