import styled, { css } from 'styled-components';

interface ISelectHistory {
  selectHistory: string;
}

export const TitleContainer = styled.h1<ISelectHistory>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;

  margin-top: 2.063rem;
  margin-bottom: 3.063rem;

  button {
    width: 12rem;
    height: 2.5rem;
    border: none;

    border-radius: 0.313rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 1.625rem;
    text-align: center;
  }

  ${props =>
    props.selectHistory === 'Closers'
      ? css`
          button.closer-history {
            background: var(--blue-100);
            color: var(--blue-700);
          }

          button.queue-history {
            background: none;
            color: var(--gray-500);
          }
        `
      : css`
          button.queue-history {
            background: var(--blue-100);
            color: var(--blue-700);
          }

          button.closer-history {
            background: none;
            color: var(--gray-500);
          }
        `}
`;

export const Separator = styled.hr`
  margin-top: 0.25rem;
  width: 11.938rem;
  height: 0.0625rem;
`;
