import { useEffect, useState, useCallback, ChangeEvent } from 'react';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import addIcon from '../../../assets/icons/addIcon.svg';
import verifyIcon from '../../../assets/icons/verify.svg';
import deleteIcon from '../../../assets/icons/delete.svg';
import editIcon from '../../../assets/icons/edit.svg';
import doubleVerifyIcon from '../../../assets/icons/boubleVerify.svg';

import {
  MainContainer,
  TableContainer,
  Table,
  Thead,
  TableRow,
  HeadCell,
  Tbody,
  BodyCell,
  QueueInput,
  ButtonDelete,
  ButtonEdit,
  CreateQueueButton,
  ButtonSave,
} from './styles';
import { confirmModal } from '../../../components/ModalConfirm';

interface IQueue {
  id: string;
  queue_name: string;
  type: string;
  valor_min?: number;
  valor_max?: number;
  trf_tj?: string;
  loas?: string;
  active?: boolean;
  reserve_queue?: string;
  joker_queue?: string;
  line?: string;
}

export function CreateQueues(): JSX.Element {
  const [queues, setQueues] = useState<IQueue[]>([]);
  const [queueName, setQueueName] = useState('');
  const [updateQueue, setupdateQueue] = useState<IQueue>({} as IQueue);
  const [editPosition, setEditPosition] = useState(false);
  const [queueID, setQueueID] = useState('');

  const [saveNewQueue, setSaveNewQueue] = useState(false);

  useEffect(() => {
    api.get('/schedule/queues').then(response => {
      setQueues(response.data.filter(queue => queue.active));
    });
  }, []);

  const handleVerifyEditQueueName = useCallback(
    (queue_name: string, queue_id: string) => {
      setQueueID(queue_id);
      setQueueName(queue_name);
      setEditPosition(true);
    },
    [],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value, checked } = event.target;

      if (name === 'active') {
        setupdateQueue({ ...updateQueue, id: queueID, [name]: checked });
      } else {
        setupdateQueue({ ...updateQueue, id: queueID, [name]: value });
      }
    },
    [queueID, updateQueue],
  );

  const verifyIsTrue = useCallback((is_checked: boolean) => {
    return is_checked ? <img src={verifyIcon} alt="verify" /> : '';
  }, []);

  const deleteQueueSucess = useCallback(() => {
    toast.success('Queue desativada com sucesso');
  }, []);

  const deleteQueueError = useCallback(() => {
    toast.error('Não foi possível desativar a queue!');
  }, []);

  const handleEditQueueError = useCallback((message: string) => {
    Swal.fire('Atenção!', message, 'warning');
  }, []);

  const handleCrateQueue = useCallback(() => {
    const createQueue = {
      id: '1',
      queue_name: '',
      type: '',
      trf1: false,
      trf2: false,
      trf3: false,
      trf4: false,
      trf5: false,
      loa1: '',
      loa2: '',
      active: false,
      reserve_queue: '',
      joker_queue: '',
    };

    queues.push(createQueue);
    handleVerifyEditQueueName('', '');
    setSaveNewQueue(true);
  }, [handleVerifyEditQueueName, queues]);

  const handleEditQueue = useCallback(async () => {
    if (saveNewQueue) {
      try {
        const { data } = await api.post('/schedule/create-new-queue', {
          queue_name: updateQueue.queue_name,
          type: updateQueue.type,
          valor_min: updateQueue.valor_min,
          valor_max: updateQueue.valor_max,
          trf_tj: updateQueue.trf_tj,
          loas: updateQueue.loas,
          active: updateQueue.active,
          reserve_queue: updateQueue.reserve_queue,
          joker_queue: updateQueue.joker_queue,
          line: updateQueue.line,
        });

        const indexUpdatedQueue = queues.findIndex(
          foundQueue => foundQueue.queue_name === '',
        );

        const tempQueue = [...queues];

        tempQueue[indexUpdatedQueue] = data;
        tempQueue.sort((a, b) => Number(a.queue_name) - Number(b.queue_name));
        setQueues(tempQueue);

        setupdateQueue({} as IQueue);
        setQueueName('');
        setEditPosition(false);
        setSaveNewQueue(false);
        toast.success('Queue criada com sucesso!');
      } catch (error) {
        handleEditQueueError(error.response.data.message);
      }
    } else {
      try {
        const { data } = await api.put('/schedule/update-queue', {
          id: queueID,
          queue_name: updateQueue.queue_name,
          type: updateQueue.type,
          valor_min: updateQueue.valor_min,
          valor_max: updateQueue.valor_max,
          trf_tj: updateQueue.trf_tj,
          loas: updateQueue.loas,
          active: updateQueue.active,
          reserve_queue: updateQueue.reserve_queue,
          joker_queue: updateQueue.joker_queue,
          line: updateQueue.line,
        });

        const indexUpdatedQueue = queues.findIndex(
          foundQueue => foundQueue.id === queueID,
        );

        const tempQueue = [...queues];

        tempQueue[indexUpdatedQueue] = data;

        setQueues(tempQueue);

        setupdateQueue({} as IQueue);
        setQueueName('');
        setEditPosition(false);
        toast.success('Queue editada com sucesso!');
      } catch (error) {
        handleEditQueueError(error.response.data.message);
      }
    }
  }, [handleEditQueueError, queueID, queues, saveNewQueue, updateQueue]);

  const handleDeleteQueue = useCallback(
    async (queue_id: string) => {
      const confirm = await confirmModal(
        'Tem certeza que deseja desativar a queue?',
      );

      if (confirm) {
        try {
          const { data } = await api.put('/schedule/update-queue', {
            id: queue_id,
            active: false,
          });

          deleteQueueSucess();

          const indexUpdatedQueue = queues.findIndex(
            foundQueue => foundQueue.id === queue_id,
          );

          const tempQueue = [...queues];

          tempQueue[indexUpdatedQueue] = data;

          setQueues(tempQueue);
        } catch {
          deleteQueueError();
        }
      }
    },
    [deleteQueueError, deleteQueueSucess, queues],
  );

  return (
    <MainContainer>
      <TableContainer>
        <Table>
          <Thead>
            <TableRow className="head-row">
              <HeadCell>Nome</HeadCell>
              <HeadCell>Tipo</HeadCell>
              <HeadCell>LINHA</HeadCell>
              <HeadCell>Valor Mín</HeadCell>
              <HeadCell>Valor Máx</HeadCell>
              <HeadCell>TRF/TJ</HeadCell>
              <HeadCell>Loas</HeadCell>
              <HeadCell>Ativo</HeadCell>
              <HeadCell>Reserva</HeadCell>
              <HeadCell>Coringa</HeadCell>
              <HeadCell> </HeadCell>
            </TableRow>
          </Thead>

          <Tbody>
            {queues &&
              queues.map(queuesInfo => (
                <TableRow className="body-row" key={queuesInfo.id}>
                  <BodyCell>
                    <QueueInput
                      onChange={handleInputChange}
                      name="queue_name"
                      verifyQueueName={queuesInfo.queue_name === queueName}
                      readOnly={queuesInfo.queue_name !== queueName}
                      defaultValue={queuesInfo.queue_name}
                    />
                  </BodyCell>
                  <BodyCell>
                    <QueueInput
                      onChange={handleInputChange}
                      name="type"
                      verifyQueueName={queuesInfo.queue_name === queueName}
                      readOnly={queuesInfo.queue_name !== queueName}
                      defaultValue={queuesInfo.type.toUpperCase()}
                    />
                  </BodyCell>
                  <BodyCell>
                    <QueueInput
                      onChange={handleInputChange}
                      name="line"
                      verifyQueueName={queuesInfo.queue_name === queueName}
                      readOnly={queuesInfo.queue_name !== queueName}
                      defaultValue={queuesInfo.line}
                    />
                  </BodyCell>
                  <BodyCell>
                    <QueueInput
                      onChange={handleInputChange}
                      name="valor_min"
                      verifyQueueName={queuesInfo.queue_name === queueName}
                      readOnly={queuesInfo.queue_name !== queueName}
                      defaultValue={queuesInfo.valor_min}
                    />
                  </BodyCell>
                  <BodyCell>
                    <QueueInput
                      onChange={handleInputChange}
                      name="valor_max"
                      verifyQueueName={queuesInfo.queue_name === queueName}
                      readOnly={queuesInfo.queue_name !== queueName}
                      defaultValue={queuesInfo.valor_max}
                    />
                  </BodyCell>
                  <BodyCell>
                    <QueueInput
                      onChange={handleInputChange}
                      name="trf_tj"
                      verifyQueueName={queuesInfo.queue_name === queueName}
                      readOnly={queuesInfo.queue_name !== queueName}
                      defaultValue={queuesInfo.trf_tj}
                    />
                  </BodyCell>
                  <BodyCell>
                    <QueueInput
                      onChange={handleInputChange}
                      name="loas"
                      verifyQueueName={queuesInfo.queue_name === queueName}
                      readOnly={queuesInfo.queue_name !== queueName}
                      defaultValue={queuesInfo.loas}
                    />
                  </BodyCell>

                  <BodyCell>
                    {queuesInfo.queue_name === queueName ? (
                      <QueueInput
                        className="checkbox-cell"
                        type="checkbox"
                        onChange={handleInputChange}
                        name="active"
                        verifyQueueName={queuesInfo.queue_name === queueName}
                        defaultChecked={verifyIsTrue(queuesInfo.active) !== ''}
                      />
                    ) : (
                      verifyIsTrue(queuesInfo.active)
                    )}
                  </BodyCell>
                  <BodyCell>
                    <QueueInput
                      onChange={handleInputChange}
                      name="reserve_queue"
                      verifyQueueName={queuesInfo.queue_name === queueName}
                      readOnly={queuesInfo.queue_name !== queueName}
                      defaultValue={queuesInfo.reserve_queue}
                    />
                  </BodyCell>
                  <BodyCell>
                    <QueueInput
                      onChange={handleInputChange}
                      name="joker_queue"
                      verifyQueueName={queuesInfo.queue_name === queueName}
                      readOnly={queuesInfo.queue_name !== queueName}
                      defaultValue={queuesInfo.joker_queue}
                    />
                  </BodyCell>
                  <BodyCell className="buttons-cell">
                    {queuesInfo.queue_name === queueName ? (
                      <ButtonSave onClick={() => handleEditQueue()}>
                        <img src={doubleVerifyIcon} alt="Save Icon" />
                      </ButtonSave>
                    ) : (
                      <>
                        <ButtonDelete
                          noClickable={
                            editPosition && queuesInfo.queue_name !== queueName
                          }
                          disabled={
                            editPosition && queuesInfo.queue_name !== queueName
                          }
                          onClick={() => handleDeleteQueue(queuesInfo.id)}
                        >
                          <img src={deleteIcon} alt="Delete Icon" />
                        </ButtonDelete>

                        <ButtonEdit
                          noClickable={
                            editPosition && queuesInfo.queue_name !== queueName
                          }
                          disabled={
                            editPosition && queuesInfo.queue_name !== queueName
                          }
                          onClick={() =>
                            handleVerifyEditQueueName(
                              queuesInfo.queue_name,
                              queuesInfo.id,
                            )
                          }
                        >
                          <img src={editIcon} alt="Edit Icon" />
                        </ButtonEdit>
                      </>
                    )}
                  </BodyCell>
                </TableRow>
              ))}
          </Tbody>
        </Table>
      </TableContainer>

      <CreateQueueButton
        type="submit"
        onClick={handleCrateQueue}
        noClickable={saveNewQueue || editPosition}
      >
        Adicionar queue
        <img src={addIcon} alt="Add Icon" />
      </CreateQueueButton>
    </MainContainer>
  );
}
